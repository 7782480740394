import { API_BASE_URL, API_BASE_URL_V2, AUTH_API_BASE_URL, OAUTH_API_BASE_URL } from '~/constants'
import { $axios } from '~/lib/axios'
import { shared } from '~/lib/shared'
import {
    ApiResponseI,
    AuthResponseI,
    BusinessAccessT,
    BusinessI,
    BusinessRequestBodyI,
    CategoryI,
    CollectionApiParams,
    CollectionApiResponse,
    CurrencyCode,
    CustomerFilterI,
    CustomerI,
    CustomerReportFilterI,
    CustomerReportI,
    GetRepsonseCollectionI,
    HistoryFilterI,
    HistoryI,
    HistoryUpdateI,
    ProdcutTotalI,
    ProductFilterI,
    ProductI,
    ProductRequestBodyI,
    ProductSellingPriceRequestBodyI,
    ProfileI,
    SingleApiResponse,
    StaffFilterI,
    StaffI,
    StockActionT,
    SupplierFilterI,
    SupplierI,
    UpdateStaffI,
    UpdateStockI
} from '~/types'
import { localStore, StoreKey } from '~/utils/local-store'
import { $error, $log } from '~/utils/logger'

/**
 * Get auth token
 */
export const AuthToken = async (firebaseToken: string): Promise<ApiResponseI<AuthResponseI>> => {
    return $axios
        .post<AuthResponseI>(`${AUTH_API_BASE_URL}/firebase`, {
            firebaseToken
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

export const AuthTokenOtpless = async (token: string): Promise<ApiResponseI<AuthResponseI>> => {
    return $axios
        .post<AuthResponseI>(`${AUTH_API_BASE_URL}/otpless`, {
            token
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Oauth Login
 */
export const OAuthLogin = async (token: string): Promise<ApiResponseI<AuthResponseI>> => {
    return $axios
        .post<AuthResponseI>(`${OAUTH_API_BASE_URL}/firebase/login`, {
            token
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Oauth Register
 */
export const OAuthRegister = async (token: string): Promise<ApiResponseI<AuthResponseI>> => {
    return $axios
        .post<AuthResponseI>(`${OAUTH_API_BASE_URL}/firebase/register`, {
            token
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Oauth Sign In
 */
export const OAuthSignIn = async (token: string): Promise<ApiResponseI<AuthResponseI>> => {
    return $axios
        .post<AuthResponseI>(`${OAUTH_API_BASE_URL}/firebase`, {
            token
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}
/**
 * Get all business
 */
export const GetBusiness = async (): Promise<ApiResponseI<BusinessI[]>> => {
    return $axios
        .get<BusinessI[]>(`${API_BASE_URL}/business`)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Create business
 */
export const CreateBusiness = async (data: BusinessRequestBodyI): Promise<ApiResponseI<BusinessI>> => {
    return $axios
        .post<BusinessI>(`${API_BASE_URL}/business`, data)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Update business
 */
export const UpdateBusiness = async (
    businessId: string,
    data: BusinessRequestBodyI
): Promise<ApiResponseI<boolean>> => {
    return $axios
        .patch<boolean>(`${API_BASE_URL}/business/${businessId}`, data)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Create product
 */
export const CreateProduct = async (
    businessId: string,
    data: Partial<ProductRequestBodyI>
): Promise<ApiResponseI<ProductI>> => {
    const params: Omit<Partial<ProductRequestBodyI>, 'productId'> = {
        buyingPrice: data.buyingPrice,
        imageUrl: data.imageUrl,
        name: data.name,
        quantity: data.quantity,
        sellingPrice: data.sellingPrice,
        sku: data.sku,
        categoryId: data.categoryId
    }

    return $axios
        .post<ProductI>(`${API_BASE_URL}/business/${businessId}/product`, params)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Update product
 */
export const UpdateProduct = async (
    businessId: string,
    params: Partial<ProductRequestBodyI>
): Promise<ApiResponseI<ProductI>> => {
    return $axios
        .patch<ProductI>(`${API_BASE_URL}/business/${businessId}/product`, params)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Add selling prices
 * @param businessId
 * @param productId
 * @param data
 * @returns
 */
export const AddSellingPrices = async (
    businessId: string,
    productId: string,
    data: ProductSellingPriceRequestBodyI[]
): Promise<boolean> => {
    return $axios
        .post<boolean>(`${API_BASE_URL}/business/${businessId}/product/prices/bulk`, {
            productId,
            sellingPrices: data.map((item) => ({
                name: item.name,
                sellingPrice: item.price
            }))
        })
        .then((res) => {
            if (res.status === 200) {
                return true
            }

            return false
        })
        .catch((e) => {
            $error(e)

            return false
        })
}

/**
 * Update selling prices
 * @param businessId
 * @param data
 * @returns
 */
export const UpdateSellingPrices = async (
    businessId: string,
    data: ProductSellingPriceRequestBodyI[]
): Promise<boolean> => {
    return $axios
        .patch<boolean>(`${API_BASE_URL}/business/${businessId}/product/prices/bulk`, {
            products: data.map((item) => ({
                productPriceID: item.id,
                productPriceName: item.name,
                sellingPrice: item.price
            }))
        })
        .then((res) => {
            if (res.status === 200) {
                return true
            }

            return false
        })
        .catch((e) => {
            $error(e)

            return false
        })
}

/**
 * Delete selling prices
 * @param businessId
 * @param data
 * @returns
 */
export const DeleteSellingPrices = async (businessId: string, data: string[]): Promise<boolean> => {
    return $axios
        .delete<boolean>(`${API_BASE_URL}/business/${businessId}/product/prices/bulk`, {
            data: {
                productPriceIDS: data
            }
        })
        .then((res) => {
            if (res.status === 200) {
                return true
            }

            return false
        })
        .catch((e) => {
            $error(e)

            return false
        })
}

/**
 * Delete product
 */
export const DeleteProduct = async (businessId: string, productId: string): Promise<ApiResponseI<boolean>> => {
    return $axios
        .delete(`${API_BASE_URL}/business/${businessId}/product`, {
            data: {
                productId
            }
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Get total product
 */
export const GetTotalProduct = async (businessId: string): Promise<ApiResponseI<number>> => {
    return $axios
        .get<{ total: number }>(`${API_BASE_URL}/business/${businessId}/product/total`)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data.total
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Get all categories
 */
export const GetCategory = async (
    businessId: string
): Promise<ApiResponseI<GetRepsonseCollectionI<'categories', CategoryI>>> => {
    return $axios
        .get<GetRepsonseCollectionI<'categories', CategoryI>>(`${API_BASE_URL}/business/${businessId}/product/category`)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Create category
 */
export const CreateCategory = async (businessId: string, data: CategoryI): Promise<ApiResponseI<CategoryI>> => {
    const params: Omit<CategoryI, 'categoryId'> = {
        name: data.name
    }
    return $axios
        .post<CategoryI>(`${API_BASE_URL}/business/${businessId}/product/category`, params)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Edit category
 */
export const EditCategory = async (businessId: string, data: CategoryI): Promise<ApiResponseI<boolean>> => {
    return $axios
        .patch<boolean>(`${API_BASE_URL}/business/${businessId}/product/category`, data)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Delete category
 */
export const DeleteCategory = async (businessId: string, categoryId: string): Promise<ApiResponseI<boolean>> => {
    return $axios
        .delete(`${API_BASE_URL}/business/${businessId}/product/category`, {
            data: {
                categoryId
            }
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Update stock
 */
export const UpdateStock = async (
    businessId: string,
    data: UpdateStockI,
    type: StockActionT
): Promise<ApiResponseI<boolean>> => {
    return $axios
        .patch<boolean>(`${API_BASE_URL_V2}/business/${businessId}/product/stock/${type.replace('stock_', '')}`, data)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Get all histories
 */
export const GetHistory = async (
    businessId: string,
    params: HistoryFilterI
): Promise<ApiResponseI<GetRepsonseCollectionI<'histories', HistoryI>>> => {
    return $axios
        .get<GetRepsonseCollectionI<'histories', HistoryI>>(
            `${API_BASE_URL_V2}/business/${businessId}/product/stock/history`,
            {
                params
            }
        )
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

export const UpdateHistory = async (businessId: string, data: HistoryUpdateI): Promise<ApiResponseI<boolean>> => {
    return $axios.patch<boolean>(`${API_BASE_URL_V2}/business/${businessId}/product/stock/history/edit`, data)
}

/**
 * Get all staffs
 */
export const GetStaff = async (
    businessId: string,
    params: StaffFilterI
): Promise<ApiResponseI<GetRepsonseCollectionI<'staffs', StaffI>>> => {
    return $axios
        .get<GetRepsonseCollectionI<'staffs', StaffI>>(`${API_BASE_URL}/business/${businessId}/staff`, {
            params
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Update stock
 */
export const UpdateStaff = async (businessId: string, data: UpdateStaffI): Promise<ApiResponseI<boolean>> => {
    return $axios
        .patch<boolean>(`${API_BASE_URL}/business/${businessId}/staff`, data)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Delete staff
 */
export const DeleteStaff = async (businessId: string, staffId: string): Promise<ApiResponseI<boolean>> => {
    return $axios
        .delete(`${API_BASE_URL}/business/${businessId}/staff`, {
            data: {
                staffId
            }
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Get total staff
 */
export const GetTotalStaff = async (businessId: string): Promise<ApiResponseI<number>> => {
    return $axios
        .get<{ total: number }>(`${API_BASE_URL}/business/${businessId}/staff/total`)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data.total
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Create join code
 */
export const CreateJoinCode = async (businessId: string, access: BusinessAccessT[]): Promise<ApiResponseI<string>> => {
    return $axios
        .post<{ joinCode: string }>(`${API_BASE_URL}/business/${businessId}/joincode`, {
            access
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data.joinCode
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Check join code
 */
export const CheckJoinCode = async (joinCode: string): Promise<ApiResponseI<string>> => {
    return $axios
        .post<{ name: string }>(`${API_BASE_URL}/business/checkjoin`, {
            joinCode
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data.name
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Join business with code
 */
export const JoinBusinessWithCode = async (joinCode: string): Promise<ApiResponseI<BusinessI>> => {
    return $axios
        .post<BusinessI>(`${API_BASE_URL}/business/join`, {
            joinCode
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Get user profile
 */
export const GetProfile = async (): Promise<ApiResponseI<ProfileI>> => {
    return $axios
        .get<ProfileI>(`${API_BASE_URL}/profile`)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

type UpdateProfileData = Omit<ProfileI, 'businesses'>

/**
 * Update user profile
 */
export const UpdateProfile = async (data: UpdateProfileData): Promise<ApiResponseI<boolean>> => {
    return $axios
        .patch(`${API_BASE_URL}/profile`, { ...data, ProfileUrl: '' })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Upload Image
 */
interface UploadImageResponseI {
    fileUrl: string
    thumbnailFileUrl: string
}
export const UploadImage = async (formData: FormData): Promise<ApiResponseI<UploadImageResponseI>> => {
    return $axios
        .post<UploadImageResponseI>(`${API_BASE_URL}/image`, formData, {
            headers: {
                'Content-Type': ' multipart/form-data'
            }
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Delete Image
 */
export const DeleteImage = async (fileUrl: string): Promise<ApiResponseI<boolean>> => {
    return $axios
        .delete(`${API_BASE_URL}/image`, {
            data: {
                fileUrl
            }
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Get all suppliers
 */
export const GetSupplier = async (
    businessId: string,
    params: SupplierFilterI
): Promise<ApiResponseI<GetRepsonseCollectionI<'businessSuppliers', SupplierI>>> => {
    return $axios
        .get<GetRepsonseCollectionI<'businessSuppliers', SupplierI>>(
            `${API_BASE_URL}/business/${businessId}/supplier`,
            {
                params
            }
        )
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Create supplier
 */
export const CreateSupplier = async (
    businessId: string,
    data: Partial<SupplierI>
): Promise<ApiResponseI<SupplierI>> => {
    return $axios
        .post<SupplierI>(`${API_BASE_URL}/business/${businessId}/supplier`, data)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Update supplier
 */
export const UpdateSupplier = async (businessId: string, data: Partial<SupplierI>): Promise<ApiResponseI<boolean>> => {
    return $axios
        .patch<boolean>(`${API_BASE_URL}/business/${businessId}/supplier`, data)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Delete supplier
 */
export const DeleteSupplier = async (businessId: string, supplierId: string): Promise<ApiResponseI<boolean>> => {
    return $axios
        .delete(`${API_BASE_URL}/business/${businessId}/supplier`, {
            data: {
                businessSupplierId: supplierId
            }
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Get all customers
 */
export const GetCustomer = async (
    businessId: string,
    params: CustomerFilterI
): Promise<ApiResponseI<GetRepsonseCollectionI<'businessCustomers', CustomerI>>> => {
    return $axios
        .get<GetRepsonseCollectionI<'businessCustomers', CustomerI>>(
            `${API_BASE_URL}/business/${businessId}/customer`,
            {
                params
            }
        )
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Create customer
 */
export const CreateCustomer = async (
    businessId: string,
    data: Partial<CustomerI>
): Promise<ApiResponseI<CustomerI>> => {
    return $axios
        .post<CustomerI>(`${API_BASE_URL}/business/${businessId}/customer`, data)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Update customer
 */
export const UpdateCustomer = async (businessId: string, data: Partial<CustomerI>): Promise<ApiResponseI<boolean>> => {
    return $axios
        .patch<boolean>(`${API_BASE_URL}/business/${businessId}/customer`, data)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Delete customer
 */
export const DeleteCustomer = async (businessId: string, customerId: string): Promise<ApiResponseI<boolean>> => {
    return $axios
        .delete(`${API_BASE_URL}/business/${businessId}/customer`, {
            data: {
                businessCustomerId: customerId
            }
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

export const ClearUserState = async () => {
    localStore.remove(StoreKey.ACCESS_TOKEN)
}

export interface BusinessReportParam {
    dateType: 'day' | 'week' | 'month' | 'year'
    orderBy: 'ASC' | 'DESC'
    startDate: string
    endDate: string
    timezone: string
}

export interface ReportData {
    date: string
    profit: number
    purchase: number
    sales: number
}

/**
 * Get all business report charts
 */
export const GetBusinessReportChart = async (
    businessId: string,
    params: BusinessReportParam
): Promise<ApiResponseI<ReportData[]>> => {
    return $axios
        .get<ReportData[]>(`${API_BASE_URL}/business/${businessId}/reports/chart`, {
            params
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

export interface SummaryData {
    currentAssetValue: number
    profitTotal: number
    purchaseTotal: number
    totalSales: number
}

/**
 * Get all business report summary
 */
export const GetBusinessReportSummary = async (
    businessId: string,
    params: { startDate: string; endDate: string; timezone: string }
): Promise<ApiResponseI<SummaryData>> => {
    return $axios
        .get<SummaryData>(`${API_BASE_URL}/business/${businessId}/reports/summary`, {
            params
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Get all business report transaction
 */
export const GetBusinessReportTransaction = async (
    businessId: string,
    params: BusinessReportParam
): Promise<ApiResponseI<ReportData[]>> => {
    return $axios
        .get<ReportData[]>(`${API_BASE_URL}/business/${businessId}/reports/transaction`, {
            params
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Download History Excel Report
 */
export const DownloadHistoryExcelReport = async (
    params: HistoryFilterI,
    businessId: string,
    fileName: string,
    currencyCode: CurrencyCode
): Promise<ApiResponseI<boolean>> => {
    return $axios
        .post<Blob>(
            `/api/download/excel`,
            {
                ...params,
                businessId: businessId,
                fileName: fileName,
                currencyCode: currencyCode
            },
            {
                responseType: 'blob'
            }
        )
        .then((res) => {
            if (res.status === 200) {
                const pdfUrl = window.URL.createObjectURL(res.data)
                const link = document.createElement('a')
                link.href = pdfUrl

                link.setAttribute('download', fileName + '.xlsx')

                document.body.appendChild(link)

                link.click()

                return {
                    data: true
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Get stock report
 */
export const GetProductTotal = async (businessId: string): Promise<ApiResponseI<{ data: ProdcutTotalI }>> => {
    return $axios
        .get<{ data: ProdcutTotalI }>(`${API_BASE_URL_V2}/business/${businessId}/product/total`)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Download Excel Report
 */
export const DownloadExcelReport: <T extends Record<string, any>>(
    params: T,
    fileName: string,
    template: string,
    endpoint: string,
    mapToData?: string | string[],
    currencyCode?: CurrencyCode
) => Promise<ApiResponseI<boolean>> = async (
    params,
    fileName,
    template,
    endpoint,
    mapToData,
    currencyCode
): Promise<ApiResponseI<boolean>> => {
    return $axios
        .post<Blob>(
            `/api/download/report`,
            {
                fileName: fileName,
                template,
                endpoint: `${endpoint}?${new URLSearchParams(params as Record<any, any>)}`,
                mapToData,
                currencyCode
            },
            {
                responseType: 'blob'
            }
        )
        .then(async (res) => {
            if (res.status === 200) {
                try {
                    const buffer = Array.from(new Uint8Array(await res.data.arrayBuffer()))
                    AppChannel.postMessage('download:' + buffer)
                    $log('Download', 'Post Message', buffer)
                } catch (e) {
                    const fileUrl = window.URL.createObjectURL(res.data)
                    const link = document.createElement('a')
                    link.href = fileUrl

                    link.setAttribute('download', fileName + '.xlsx')

                    document.body.appendChild(link)

                    link.click()
                    $log('Download', 'Default')
                }

                return {
                    data: true
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Download Product Excel Report
 */
export const DownloadProductExcelReport = async (
    params: CollectionApiParams<ProductFilterI>,
    businessId: string,
    fileName: string,
    currencyCode: CurrencyCode
): Promise<ApiResponseI<boolean>> => {
    return $axios
        .post<Blob>(
            `/api/download/product`,
            {
                params,
                businessId: businessId,
                fileName: fileName,
                currencyCode: currencyCode
            },
            {
                responseType: 'blob'
            }
        )
        .then((res) => {
            if (res.status === 200) {
                const pdfUrl = window.URL.createObjectURL(res.data)
                const link = document.createElement('a')
                link.href = pdfUrl

                link.setAttribute('download', fileName + '.xlsx')

                document.body.appendChild(link)

                link.click()

                return {
                    data: true
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

export const GetCustomerReport = async (
    businessId: string,
    params: CustomerReportFilterI
): Promise<ApiResponseI<GetRepsonseCollectionI<'data', CustomerReportI>>> => {
    return $axios
        .get<GetRepsonseCollectionI<'data', CustomerReportI>>(
            `${API_BASE_URL}/business/${businessId}/reports/customer`,
            {
                params
            }
        )
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: shared.requestErrorMessage
                }
            }
        })
}

/**
 * Get collection data
 * @param url
 * @param params
 * @returns
 */
export const getCollection = async <T, P, F extends string = 'data', W extends boolean = false>(
    url: string,
    params?: CollectionApiParams<P>,
    baseUrl: keyof typeof baseUrlMap = 'v1'
) => {
    const response = await $axios.get<
        W extends false ? CollectionApiResponse<T, F> : { data?: CollectionApiResponse<T, F> }
    >(`${baseUrlMap[baseUrl]}${url}`, {
        params
    })

    return response.data
}

/**
 * Get single data
 * @param url
 * @returns
 */
export const getSingle = async <T>(url: string, baseUrl: keyof typeof baseUrlMap = 'v1') => {
    const response = await $axios.get<SingleApiResponse<T>>(`${baseUrlMap[baseUrl]}${url}`)

    return response.data
}

const baseUrlMap = {
    v1: API_BASE_URL,
    v2: API_BASE_URL_V2,
    auth: AUTH_API_BASE_URL,
    oauth: OAUTH_API_BASE_URL
}
