export const pageMap: Record<
    string,
    {
        title?: string
        header?: string
        layout?: 'dashboard' | 'fullscreen'
        auth?: boolean
        hideNavigation?: boolean
        mobile?: boolean
    }
> = {
    '/login': {
        title: 'common.login.title',
        header: '',
        layout: 'fullscreen',
        auth: false,
        mobile: true
    },
    '/maintenance': {
        title: 'Maintenance',
        header: 'Maintenance',
        layout: 'fullscreen',
        auth: false
    },
    '/business': {
        title: 'common.business',
        header: 'common.business',
        layout: 'dashboard',
        auth: true,
        mobile: true,
        hideNavigation: true
    },
    '/business/account': {
        title: 'common.account',
        header: 'common.account',
        layout: 'dashboard',
        auth: true
    },
    '/business/category': {
        title: 'category.category_list.title',
        header: 'category.category_list.title',
        layout: 'dashboard',
        auth: true
    },
    '/business/customer': {
        title: 'common.customer',
        header: 'common.customer',
        layout: 'dashboard',
        auth: true
    },
    '/business/customer-report': {
        title: 'customer.report.title',
        header: 'customer.report.title',
        layout: 'dashboard',
        auth: true,
        mobile: true
    },
    '/business/history': {
        title: 'common.history',
        header: 'common.history',
        layout: 'dashboard',
        auth: true
    },
    '/business/product': {
        title: 'common.product',
        header: 'common.product',
        layout: 'dashboard',
        auth: true
    },
    '/business/product/add': {
        title: 'common.add_product',
        header: 'common.add_product',
        layout: 'dashboard',
        auth: true
    },
    '/business/product/edit': {
        title: 'common.edit_product',
        header: 'common.edit_product',
        layout: 'dashboard',
        auth: true
    },
    '/business/staff': {
        title: 'common.staff',
        header: 'common.staff',
        layout: 'dashboard',
        auth: true
    },
    '/business/staff/add': {
        title: 'common.staff',
        header: 'common.staff',
        layout: 'dashboard',
        auth: true
    },
    '/business/stock-audit': {
        title: 'common.stock_audit',
        header: 'common.stock_audit',
        layout: 'dashboard',
        auth: true
    },
    '/business/stock-in': {
        title: 'common.stock_in',
        header: 'common.stock_in',
        layout: 'dashboard',
        auth: true
    },
    '/business/stock-out': {
        title: 'common.stock_out',
        header: 'common.stock_out',
        layout: 'dashboard',
        auth: true
    },
    '/business/supplier': {
        title: 'common.supplier',
        header: 'common.supplier',
        layout: 'dashboard',
        auth: true
    },
    '/business/report': {
        title: 'report.title',
        header: 'report.title',
        layout: 'dashboard',
        auth: true,
        mobile: true
    },
    '/business/stock-report': {
        title: 'stock_report.title',
        header: 'stock_report.title',
        layout: 'dashboard',
        auth: true,
        mobile: true
    },
    '/onboarding': {
        title: 'onboarding.welcome',
        header: 'onboarding.welcome',
        layout: 'fullscreen',
        auth: true,
        hideNavigation: true,
        mobile: true
    },
    '/business/payment/order': {
        title: 'payment.order.title',
        header: 'payment.order.title',
        layout: 'fullscreen',
        auth: true,
        hideNavigation: true
    },
    '/': {
        header: 'kelola.co',
        layout: 'fullscreen',
        auth: false
    },
    '/_error': {
        title: 'kelola.co',
        header: 'kelola.co',
        layout: 'fullscreen',
        auth: false
    }
}
