import { ClearUserState } from '~/api'
import { Button } from '~/components/Common/Button'
import Icon from '~/components/Icon'
import $posthog from '~/lib/posthog'
import $sentry from '~/lib/sentry'

import { $error } from '~/utils/logger'
import { useLanguage } from '../Language/LanguageProvider'

export const Logout = () => {
    const { t } = useLanguage()

    return (
        <Button
            text={t('common.logout')}
            type="outlined"
            size="sm"
            icon={
                <span className="text-red contents">
                    <Icon name="logout" size="lg"></Icon>
                </span>
            }
            onClick={logout}></Button>
    )
}

export const logout = async () => {
    try {
        $posthog.reset()
        $sentry.clearUser()
        ClearUserState()
        window.location.href = '/login'
    } catch (error: any) {
        $error('Sesuatu yang tidak beres !!', error)
    }
}
