import { useEffect, useState } from 'react'
import { LanguageCode } from '~/types'

export const useLocale = (locale: LanguageCode) => {
    const [data, setData] = useState<Record<string, any>>()

    useEffect(() => {
        getData(locale)
    }, [locale])

    const getData = async (locale: LanguageCode) => {
        const res = await fetch(`/locales/${locale}.json`)
        const langData = await res.json()

        setData(langData)
    }

    return data
}
