import axios from 'axios'
import { localStore, StoreKey } from '~/utils/local-store'

export const $axios = axios.create()

/**
 * add authorization token on each request
 * @returns
 */
export const RequestInterceptor = () => {
    $axios.interceptors.request.use(
        (config) => {
            const accessToken = localStore.get(StoreKey.ACCESS_TOKEN)?.data
            config.headers = !!config.headers ? config.headers : {}

            if (accessToken) {
                config.headers['Authorization'] = `Bearer ${accessToken}`
            }

            config.headers['app-version'] = '3'
            return config
        },
        (error) => {
            Promise.reject(error)
        }
    )
}

// /**
//  * when api response with 443 then call refresh token api
//  * @param param
//  * @returns
//  */
// export const responseInterceptor = (
//   nonAuthPage: boolean,
//   snackbarHandler: (arg: SnackbarItem) => void
// ) => {
//   $axios.interceptors.response.use(
//     response => {
//       return response
//     },
//     async error => {
//       const originalRequest = error?.config
//       $log('originalRequest', originalRequest)
//       $log('error-response', error?.response)
//       $log('error-response-status', error?.response?.status)
//       if (
//         (error?.response?.status === 443 || error?.response?.status === 401) &&
//         (originalRequest?.url === `${USER_SERVICE_URL}/auth/login/standalone` ||
//           originalRequest?.url === `${USER_SERVICE_URL}/auth/users/bacon` ||
//           originalRequest?.url === `${USER_SERVICE_URL}/auth/otp/send`)
//       ) {
//         return Promise.reject(error)
//       }
//       $log('test', 1)
//       if (
//         (error?.response?.status === 443 || error?.response?.status === 401) &&
//         !originalRequest?._retry
//       ) {
//         originalRequest._retry = true
//         const accessToken = Cookie.get(STORAGE.ACCESS_TOKEN!)
//         const refToken = Cookie.get(REFRESH_TOKEN_COOKIE!)
//         const phone = Cookie.get(PHONE_NUMBER!)
//         $log('accessToken', accessToken)
//         $log('refToken', refToken)
//         $log('phone', phone)
//         if (!accessToken || !phone || !refToken) {
//           $log('removeCookieAndRedirectToLogin from', 1)
//           removeCookieAndRedirectToLogin(snackbarHandler)
//         } else {
//           const response = await refreshToken(
//             accessToken,
//             refToken,
//             nonAuthPage,
//             phone,
//             true
//           )
//           if (response?.isTokenRefresh) {
//             $axios.defaults.headers.common[
//               'Authorization'
//             ] = `Bearer ${response.newAccessToken}`
//             originalRequest.headers.Authorization = `Bearer ${response.newAccessToken}`
//             return $axios(originalRequest)
//           } else {
//             $log('removeCookieAndRedirectToLogin from', 2)
//             removeCookieAndRedirectToLogin(snackbarHandler)
//           }
//         }
//       }
//       if (error?.response?.status === 403) {
//         $log('removeCookieAndRedirectToLogin from', 3)
//         removeCookieAndRedirectToLogin(snackbarHandler)
//       }

//       if (
//         error?.response?.status !== 403 &&
//         error?.response?.status !== 443 &&
//         error?.response?.status !== 401
//       ) {
//         let apiResponseMsg = locale.id.common.response_error
//         if (error?.response?.data?.responseMessages?.in) {
//           apiResponseMsg = error.response.data.responseMessages.in
//         }
//         if (error?.response?.data?.message) {
//           apiResponseMsg = error.response.data.message
//         }
//         snackbarHandler({
//           value: true,
//           message: apiResponseMsg,
//           style: 'error'
//         })
//       }
//       return Promise.reject(error)
//     }
//   )
// }

// const removeCookieAndRedirectToLogin = (
//   snackbarHandler: (arg: SnackbarItem) => void
// ) => {
//   Cookie.remove(REFRESH_TOKEN_COOKIE!)
//   Cookie.remove(ACCESS_TOKEN_COOKIE!)
//   Cookie.remove(PHONE_NUMBER!)
//   const { platform } = queryString.parse(window.location.search)
//   if (platform === 'android') {
//     delayedCallback(() => {
//       snackbarHandler({
//         value: true,
//         message: locale.id.common.session_timeout,
//         style: 'error',
//         noTimeOut: true
//       })
//     }, 2500)
//     delayedCallback(() => {
//       closeWebView()
//     }, 3000)
//     return
//   }
//   window.location.href = '/login'
// }
