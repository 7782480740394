import Icon from '~/components/Icon'

export const navigationMenu = [
    {
        text: 'report.title',
        slug: '/report',
        icon: <Icon name="report" width={22} height={22}></Icon>,
        submenu: [
            {
                text: 'short.finance',
                slug: '/report',
                icon: <Icon name="money-outline" width={22} height={22}></Icon>
            },
            {
                text: 'short.stock',
                slug: '/stock-report',
                icon: <Icon name="report" width={22} height={22}></Icon>
            },
            {
                text: 'common.customer',
                slug: '/customer-report'
            }
        ]
    },
    {
        text: 'common.product',
        slug: '/product',
        icon: <Icon name="archive-outline" width={22} height={22}></Icon>
    },
    {
        text: 'common.stock_in',
        slug: '/stock-in',
        icon: <Icon name="download" width={22} height={22}></Icon>
    },
    {
        text: 'common.stock_out',
        slug: '/stock-out',
        icon: <Icon name="upload" width={22} height={22}></Icon>
    },
    {
        text: 'common.stock_audit',
        slug: '/stock-audit',
        icon: <Icon name="switch-vertical" width={22} height={22}></Icon>
    },
    {
        text: 'common.history',
        slug: '/history',
        icon: <Icon name="history" width={22} height={22}></Icon>
    },
    {
        text: 'common.contact',
        slug: '/contact',
        icon: <Icon name="user-group" width={22} height={22}></Icon>,
        submenu: [
            {
                text: 'common.customer',
                slug: '/customer'
            },
            {
                text: 'common.supplier',
                slug: '/supplier'
            },
            {
                text: 'common.staff',
                slug: '/staff'
            }
        ]
    }
    // {
    //     text: 'common.customer',
    //     slug: '/customer',
    //     icon: <Icon name="user-group" width={22} height={22}></Icon>
    // },
    // {
    //     text: 'common.supplier',
    //     slug: '/supplier',
    //     icon: <Icon name="box-car" width={22} height={22}></Icon>
    // },
    // {
    //     text: 'common.staff',
    //     slug: '/staff',
    //     icon: <Icon name="users-outline" width={22} height={22}></Icon>
    // }
    // {
    //     text: 'common.category',
    //     slug: '/category',
    //     icon: <Icon name="category" width={22} height={22}></Icon>
    // },
]
