import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useLocale } from '~/hooks/useLocale'
import { shared } from '~/lib/shared'
import { LanguageCode } from '~/types'
import { getValueByPath } from '~/utils'
import { useAuth } from '../Auth/AuthProvider'

export const LanguageContext = createContext<{
    locale: LanguageCode
    t: (key: string) => string
}>({
    locale: LanguageCode.EN,
    t: () => ''
})

interface LanguageProviderProps {
    children: JSX.Element | JSX.Element[]
}

export const useLanguage = () => useContext(LanguageContext)

export const LanguageProvider = ({ children }: LanguageProviderProps) => {
    const { profile } = useAuth()
    const [nextLocale, setNextLocale] = useState(LanguageCode.EN)

    const t = (key: string) => {
        const text = getValueByPath(langData, key)

        return text || key
    }

    useEffect(() => {
        const cookieValue = document.cookie
            .split('; ')
            .find((row) => row.startsWith('NEXT_LOCALE='))
            ?.split('=')[1]

        if (cookieValue) {
            setNextLocale(cookieValue as LanguageCode)
        }
    }, [])

    const locale = useMemo(
        () => profile?.language_code || nextLocale || LanguageCode.EN,
        [profile?.language_code, nextLocale]
    )

    useEffect(() => {
        shared.setRequestErrorMessage(t('common.requestErrorMessage'))
    }, [locale])

    const langData = useLocale(locale)

    return <LanguageContext.Provider value={{ locale, t }}>{children}</LanguageContext.Provider>
}
